
// -- Language
import APP_LANGUAGE from './languages/fr.js';

const routes = [
  /*
  {
    path: "/",
    title: "Pré-Home",
    view: "PreHome",
    slug: "pre-home"
  },
  */
  {
    path: "/",
    title: APP_LANGUAGE.NAV_HOME,
    view: "Home",
    slug: "home",
    menu: true,
    action: ""
  },
  {
    path: "",
    title: APP_LANGUAGE.NAV_SAVED_LOCATIONS,
    view: "",
    slug: "saved-locations",
    menu: true,
    action: "showSavedLocations"
  },
  {
    path: "/locations",
    title: APP_LANGUAGE.NAV_LOCATIONS,
    view: "Locations",
    slug: "locations",
    menu: false,
    action: ""
  }
];

if (process.env.REACT_APP_ENV === "development"){
  routes.push(
    {
      path: "/resetStore",
      title: APP_LANGUAGE.NAV_RESET_STORE,
      view: "Home",
      slug: "reset-store",
      menu: true,
      action: "resetStore"
    }
  )
}

export default routes;
