import React, { Component } from 'react';
import PropTypes from 'prop-types';

// -- Redux
import { connect } from "react-redux";
import { removeLocation  } from "../../redux/actions/actions.js";

// -- Language
import APP_LANGUAGE from '../../languages/fr.js';

// -- Material UI
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DeleteIcon from '@material-ui/icons/Delete';

// -- Components
import DialogAppBar from '../../components/Dialog/DialogAppBar.js';

const mapStateToProps = state => {
  return {
    savedLocations: state.savedLocations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeLocation: locationId => dispatch(removeLocation(locationId))
  };
};

const styles = {
  root: {
    width: '100%'
  }
};

class ConnectedSavedLocations extends Component {
  constructor(props) {
    //console.info(`.constructor`, props);
    super(props);
    this.state = {
      //
    }
  }

  componentDidMount() {
    //console.info(`[${this.constructor.name}].componentDidMount`, this.props, this.state);
  }

  handleClose = () => {
    console.log(`[${this.constructor.name}].handleClose`);

    // Passing data to parent component (Header)
    this.props.onClose();
  };

  deleteSavedLocation = (locationId) => {
    console.log(`[${this.constructor.name}].deleteSavedLocation`, locationId);
    this.props.removeLocation(locationId);  // redux storage
  }

  getButtons(){
    return [];
  }

  render() {
    //console.log(`[${this.constructor.name}].render state:`, this.state);
    //console.log(`[${this.constructor.name}].render props:`, this.props);

    const { classes, savedLocations } = this.props;

    return (
      <div className="savedLocationsContainer">

          <DialogAppBar title={APP_LANGUAGE.TITLE_SAVED_LOCATIONS} buttons={this.getButtons()} onClose={this.handleClose} />

          <div className="locations">
            <List className={classes.root}>
              {
                savedLocations.map(savedLocation => (
                  <ListItem button key={savedLocation.id}>
                    <ListItemAvatar>
                      <Avatar>
                        <LocationOnIcon />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText inset primary={savedLocation.name} />

                    <ListItemSecondaryAction>
                      <IconButton aria-label="Delete" onClick={() => this.deleteSavedLocation(savedLocation.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
              }
            </List>
          </div>
      </div>
    );
  }
}

ConnectedSavedLocations.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func
};
ConnectedSavedLocations.defaultProps = {
  onClose: f => f
}

const SavedLocations = connect(mapStateToProps, mapDispatchToProps)(ConnectedSavedLocations);

export default withStyles(styles)(SavedLocations);
