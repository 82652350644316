import React from 'react';
import ReactDOM from 'react-dom';

// -- Redux
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'

import { store, persistor } from "./redux/store/store.js";
//import index from "./js/index";

// -- Styles
import './styles/index.css';

import App from './App';

import * as serviceWorker from './serviceWorker';

// Redux Store Log
window.store = store;
// NB: Get store state :
//store.getState()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
