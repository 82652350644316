import React, { Component } from 'react';
import PropTypes from 'prop-types';

// -- Material UI
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
//import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    'align-items': 'center',
    'justify-content': 'center'
  },
  formControlLabel: {
    marginRight: 10,
    marginLeft: 10
  }
});

class RadioButtonsGroupWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: props.defaultValue
    }
  }

  componentDidMount() {
    //console.info(`[${this.constructor.name}].componentDidMount`, this.state);
  }
  componentWillReceiveProps(nextProps) {
    //console.log(`[${this.constructor.name}].componentWillReceiveProps`, this.state.selectedValue, nextProps.selectedValue);
  }
  /*
  shouldComponentUpdate(nextProps, nextState) {
    //console.log(`[${this.constructor.name}].shouldComponentUpdate`, nextProps, nextState);
    if (nextProps.selectedValue !== nextState.selectedValue){ // permet de forcer le render() si reset
      this.setState({ selectedValue: nextProps.selectedValue })
    }
    return true;
  }
  */

  handleChange = event => {
    //this.setState({ selectedValue: event.target.value }); // NB: component will use props from SearchForm vs local state

    // Passing data to parent component
    this.props.onSelect(event.target.value);
  };

  render() {
    //console.info(`[${this.constructor.name}].render`, this.props);
    // https://reactjs.org/docs/faq-functions.html

    const { classes, label, data, selectedValue } = this.props;
    //const { selectedValue } = this.state;

    return (
      <div className={classes.root}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{label}</FormLabel>
          <RadioGroup
            aria-label="position"
            name="position"
            value={selectedValue}
            onChange={this.handleChange}
            row
          >
            {data.map(d => (
              <FormControlLabel
                key={d.id}
                value={d.id}
                checked={selectedValue === d.id}
                control={<Radio color="primary" />}
                label={d.name}
                labelPlacement="top"
                className={classes.formControlLabel}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    );
  }
}

RadioButtonsGroupWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  onSelect: PropTypes.func
};
RadioButtonsGroupWrapper.defaultProps = {
  onSelect: f => f
}

export default withStyles(styles)(RadioButtonsGroupWrapper);
