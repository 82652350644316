import React, { Component } from 'react';
import PropTypes from 'prop-types';

// -- Material UI
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
  margin: {
    margin: theme.spacing.unit,
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  }
});

class ButtonWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    //console.info(`[${this.constructor.name}].componentDidMount`);
  }

  render() {
    //console.info(`[${this.constructor.name}].render`, this.props);
    // https://reactjs.org/docs/faq-functions.html

    const { classes, label, size, color, handleClick } = this.props;

    return (
      <Button variant="contained" size={size} color={color} className={classes.margin} onClick={handleClick}>
        {label}
      </Button>
    );
  }
}

ButtonWrapper.defaultProps = {
  color: null,
  size: 'large'
};
ButtonWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired
};

export default withStyles(styles)(ButtonWrapper);
