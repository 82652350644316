import React, { Component } from 'react';
import PropTypes from 'prop-types';

// -- Redux
import { connect } from "react-redux";
import { addLocation  } from "../../redux/actions/actions.js";

// -- API
import API from '../../api/API.js';
import Fetch from '../../hoc/Fetch.js';
import XHRStateChecker from '../../api/XHRStateChecker.js';

// -- Language
import APP_LANGUAGE from '../../languages/fr.js';

// -- Material UI
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

// -- Components
import DialogAppBar from '../../components/Dialog/DialogAppBar.js';
import Map from '../../components/Map/GMap.js';
import EventPanel from '../../components/Event/EventPanel.js';
import LocationStats from '../../components/Location/LocationStats.js';


const mapStateToProps = state => {
  return {
    searchFilters: state.searchFilters,
    savedLocations: state.savedLocations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addLocation: location => dispatch(addLocation(location))
  };
};

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
};

class ConnectedLocation extends Component {
  constructor(props) {
    console.info(`.constructor`, props);
    super(props);
    this.state = {
      //open: false,
      filters: typeof(props.filters) !== "undefined" ? props.filters : props.searchFilters,
      location: props.location,
      events: {},
      stats: {},
      isLocationSaved: false
    }
    // XHR
    this.XHRStateCheckerWithAPIFetchLocationEvents = null;
    this.XHRStateCheckerWithAPIFetchLocationStats = null;

    this.handleRequestResult = this.handleRequestResult.bind(this);
  }

  componentWillMount() {
    console.info(`[${this.constructor.name}].componentWillMount`, this.props, this.state);

    // On màj l'état du btn saved
    if (this.isLocationSaved(this.state.location, this.props.savedLocations)) {
      this.setState({isLocationSaved: true});
    }
  }
  componentDidMount() {
    console.info(`[${this.constructor.name}].componentDidMount`, this.props, this.state);

    // -- Requête API: getLocationEvents
    const withAPIFetchLocationEvents = Fetch(API.getLocationEvents(this.state.filters, this.props.location.id));
    this.XHRStateCheckerWithAPIFetchLocationEvents = withAPIFetchLocationEvents(XHRStateChecker);

    // -- Requête API: getLocationStats
    const withAPIFetchLocationStats = Fetch(API.getLocationStats(this.state.filters, this.props.location.id));
    this.XHRStateCheckerWithAPIFetchLocationStats = withAPIFetchLocationStats(XHRStateChecker);

  }

  componentWillReceiveProps(nextProps) {
    console.log(`[${this.constructor.name}].componentWillReceiveProps`, nextProps);
  }
  /*
  shouldComponentUpdate(nextProps, nextState) {
    console.log(`[${this.constructor.name}].shouldComponentUpdate`, nextProps);
    console.log(`[${this.constructor.name}].shouldComponentUpdate`, nextState);
    return true;
  }
*/
  handleClickOpen = () => {
    console.log(`[${this.constructor.name}].handleClickOpen`);
    //this.setState({ open: true });
  };

  handleClose = () => {
    console.log(`[${this.constructor.name}].handleClose`);
    //this.setState({ open: false });

    // Passing data to parent component
    this.props.onClose();
  };

  // Check if location  not already saved
  isLocationSaved = (location, savedLocations) => {
    let isLocationSaved = false;
    savedLocations.map( (savedLocation) => {
      if (savedLocation.id === location.id){
        isLocationSaved = true;
        console.warn(`[${this.constructor.name}].handleSave - location already saved !`, ); // TODO : ALERT
      }
    })
    return isLocationSaved;
  }

  handleSave = (location) => {
    console.log(`[${this.constructor.name}].handleSave`, location);

    if (!this.isLocationSaved(location, this.props.savedLocations)) {
      this.props.addLocation(location);  // redux storage
      this.setState({isLocationSaved: true});
    }
  };

  handleRequestResult(data){
    console.log(`[${this.constructor.name}].handleRequestResult`, data);
    if (typeof(data.events) !== "undefined"){
      this.XHRStateCheckerWithAPIFetchLocationEvents = null;  // NB: sinon update infini !
      this.setState({events: data.events});
    }
    else if (typeof(data.stats) !== "undefined"){
      this.XHRStateCheckerWithAPIFetchLocationStats = null;  // NB: sinon update infini !
      this.setState({stats: data.stats});
    }
  }

  getLocationEventsList(events){
    console.log(`[${this.constructor.name}].getLocationEventsList`, events);


    return (
      <List className={this.props.classes.root}>
        {
          Object.keys(events).map( (date, index) => {
            console.log('events:', date, events[date])
            return (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemText primary={APP_LANGUAGE.EVENTS_BY_LOCATION_LABEL} secondary={date} />
                </ListItem>
                <Divider component="li" />
                {
                  events[date].map( (event) => {
                    return (
                      <EventPanel key={event.id} event={event}/>
                    )
                  })
                }
              </React.Fragment>
            )
          })
        }
      </List>
    )
  }

  getLocationStats(stats, location){
    console.log(`[${this.constructor.name}].getLocationStats`, stats, location);

    return (
      <LocationStats stats={stats} location={location} />
    )
  }

  getSaveButton(isLocationSaved, location){

    if (!isLocationSaved){
      return (
        <Button className="saveBtn" color="inherit" onClick={() => this.handleSave(location)}>
          {APP_LANGUAGE.ACTION_STORE_LOCATION}
        </Button>
      )
    }
    else {
      return (
        <Button className="saveBtn saved" color="inherit" disabled>
          {APP_LANGUAGE.LOCATION_ALREADY_SAVED}
        </Button>
      )
    }
  }

  // TODO :
  // 1/ Charger stats pour le lieu (avec filtres ?)
  // 2/ charger events pour le lieu (avec filtre limité à idType, period, idLocation)

  render() {
    //console.log(`[${this.constructor.name}].render state:`, this.state);
    //console.log(`[${this.constructor.name}].render props:`, this.props);

    const { classes, location, searchFilters } = this.props;
    const { events, stats, isLocationSaved } = this.state;
    console.log(`[${this.constructor.name}].render`, location, events);

    // -- Requête API > à déplacer dans componentDidMount (avec setState) ?
    const XHRStateCheckerWithAPIFetchLocationEvents = this.XHRStateCheckerWithAPIFetchLocationEvents ? this.XHRStateCheckerWithAPIFetchLocationEvents:null;
    const XHRStateCheckerWithAPIFetchLocationStats = this.XHRStateCheckerWithAPIFetchLocationStats ? this.XHRStateCheckerWithAPIFetchLocationStats:null;

    return (
      <div className="locationContainer">

          <DialogAppBar buttons={this.getSaveButton(isLocationSaved, location)} onClose={this.handleClose} />

          <div className="infos">
            <Typography variant="h1" color="inherit" className={classes.flex}>
              {location.name}
            </Typography>
            <Typography variant="h2" color="inherit" className={classes.flex}>
              {location.address}
            </Typography>
          </div>

          <div className="map">
            <Map locations={[location]}/>
          </div>

          <div className="stats">
            {
              (XHRStateCheckerWithAPIFetchLocationStats ? <XHRStateCheckerWithAPIFetchLocationStats message={APP_LANGUAGE.API_LOCATION_STATS} onRequestResult={this.handleRequestResult}/> : this.getLocationStats(stats, location))
            }
          </div>

          <div className="events">
            {
              (XHRStateCheckerWithAPIFetchLocationEvents ? <XHRStateCheckerWithAPIFetchLocationEvents message={APP_LANGUAGE.API_LOCATION_EVENTS} onRequestResult={this.handleRequestResult}/> : this.getLocationEventsList(events))
            }
          </div>
      </div>
    );
  }
}

ConnectedLocation.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onClose: PropTypes.func
};
ConnectedLocation.defaultProps = {
  onClose: f => f
}

const Location = connect(mapStateToProps, mapDispatchToProps)(ConnectedLocation);

export default withStyles(styles)(Location);
