import React, { Component } from 'react';
import PropTypes from 'prop-types';

// -- Material UI
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

// -- Helpers
import { removeItem } from '../../helpers.js';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    'align-items': 'center',
    'justify-content': 'center'
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 280,
    maxWidth: 350,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(id, that) {
  return {
    fontWeight:
      that.state.ids.indexOf(id) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}

class MultipleSelectWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ids: props.ids // already selected values
    }
  }

  handleChange = event => {
    //console.log(`[${this.constructor.name}].handleChange`, event.target.value);
    //this.setState({ ids: event.target.value }); // NB: component will use props from SearchForm vs local state

    // Passing data to parent component
    this.props.onSelect(event.target.value);
  };

  handleDelete = value => event => {
    const ids = this.props.ids; // NB: component will use props from SearchForm vs local state
    //const ids = this.state.ids;

    let removed = false;
    let filteredIds = [];

    ids.forEach((id, index) => {
      if (!removed && (value === id)){
        filteredIds = removeItem(ids, index);
        removed = true;
      }
    });
    //this.setState({ ids: filteredIds });  // NB: component will use props from SearchForm vs local state

    // Passing data to parent component
    this.props.onDelete(filteredIds);
  }

/*
  handleChangeMultiple = event => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({
      name: value,
    });
  };
*/
  getObjFromStackValue(value, data){
    let obj = {};
    data.map(d => {
      if (value === d.id) obj = d;
      return true;
    });
    return obj;
  }

  componentDidMount() {
    //console.info(`[${this.constructor.name}].componentDidMount`);
  }
/*
  shouldComponentUpdate(nextProps, nextState) {
    console.log(`[${this.constructor.name}].shouldComponentUpdate`, nextProps, nextState);
    if (nextProps.ids !== nextState.ids){ // permet de forcer le render() si reset
      this.setState({ ids: nextProps.ids })
    }
    return true;
  }
*/
  render() {
    //console.info(`[${this.constructor.name}].render props.ids`, this.props.ids);
    //console.info(`[${this.constructor.name}].render state.ids`, this.state.ids);

    if (typeof (this.props.data) === "undefined" || !this.props.data.length){
      throw new Error(`[${this.constructor.name}]] no data !`);
    }

    const { classes, label, data, ids } = this.props;
    //const { ids } = this.state;

    const ASSETS_URI = process.env.REACT_APP_ASSETS_URI;
    const EVENT_TYPES_PICTOS_DIR = ASSETS_URI + process.env.REACT_APP_ASSETS_EVENT_TYPES_PICTOS_DIR;
    const PICTO_FILE_EXT = '.png';

    return (
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-multiple-chip">{label}</InputLabel>
            <Select
              multiple
              value={ids}
              onChange={this.handleChange}
              input={<Input id="select-multiple-chip" />}
              renderValue={selected => (
                <div className={classes.chips}>
                  {selected.map(value => (

                    <Chip
                      key={value}
                      avatar={<Avatar alt={this.getObjFromStackValue(value, data).name} src={EVENT_TYPES_PICTOS_DIR + this.getObjFromStackValue(value, data).css + PICTO_FILE_EXT} />}
                      label={this.getObjFromStackValue(value, data).name}
                      onDelete={this.handleDelete(value)}
                      className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {data.map(d => (
                <MenuItem key={d.id} value={d.id} style={getStyles(d.id, this)}>
                  {d.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
      </div>
    );
  }
}

MultipleSelectWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  ids: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func
};
MultipleSelectWrapper.defaultProps = {
  ids: [],
  onSelect: f => f,
  onDelete: f => f
}

export default withStyles(styles, { withTheme: true })(MultipleSelectWrapper);
