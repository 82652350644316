const APP_LANGUAGE = {

  API_LOADING: "Loading API data ...",
  API_SEARCH_FILTERS_LOADING: "Loading SearchForm data ...",
  API_TOP_LOCATIONS_BY_TYPE_LOADING: "Loading most popular locations ...",
  API_LOCATION_EVENTS: "Loading last events for this location ...",
  API_LOCATION_STATS: "Loading stats for this location ...",

  NAV_HOME: "Accueil",
  NAV_SAVED_LOCATIONS: "Mes lieux sauvegardés",
  NAV_ABOUT: "A Propos",
  NAV_LOCATIONS: "Lieux",
  NAV_RESET_STORE: "Vider le store",

  FORM_TITLE: "Rechercher des Gigs",
  FORM_SELECT_EVENT_TYPE: "Selectionner un style de musique",
  FORM_SELECT_LOCATION_ZONE: "Selectionner arrondissement / région",
  FORM_SELECT_SUBMIT: "Lancer la recherche",
  FORM_SELECT_RESET_FILTERS: "Supprimer les filtres",
  FORM_SEARCH_PERIOD: "Période de recherche",

  SEARCH_RESULT_NO_LOCATIONS: "Aucun lieu n'a été trouvé ...",

  ACTION_STORE_LOCATION: "Sauvegarder ce lieu",
  ACTION_SHOW_SAVED_LOCATIONS: "Afficher mes lieux sauvegardés",

  TITLE_SAVED_LOCATIONS: "Mes lieux sauvegardés",

  EVENT_NAME: "gig",
  EVENTS_BY_LOCATION_LABEL: "Concerts",

  EVENT_STAT_SUBTITLE: "Résumé",
  EVENT_STAT_MUSIC_TYPE: "Style de musique",
  EVENT_STAT_PRICE: "Tarif(s)",
  EVENT_STAT_DATE: "Date",
  EVENT_STAT_PRICE_FREE: "Gratuit",

  EVENT_STAT_WEBSITE: "Site internet",
  EVENT_STAT_PHONE_NBER: "Téléphone",
  EVENT_STAT_AVG_PRICE: "Prix d'entrée moyen",
  EVENT_STAT_AVG_BEER_PRICE: "Prix moyen du demi",

  LOCATION_ALREADY_SAVED: "Lieu sauvegardé"
}

export default APP_LANGUAGE;
