import React, { Component } from 'react';
import PropTypes from 'prop-types';

// -- Redux
import { connect } from "react-redux";
import { storeCurrentNavigationIndex, storeTopLocationsByEventType } from "../redux/actions/actions.js";

// -- Language
import APP_LANGUAGE from '../languages/fr.js';

// -- API
import API from '../api/API.js';
import Fetch from '../hoc/Fetch.js';
import XHRStateChecker from '../api/XHRStateChecker.js';

// -- Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
//import GridList from '@material-ui/core/GridList';
//import GridListTile from '@material-ui/core/GridListTile';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

// -- Components
import SearchForm from '../components/Form/SearchForm.js';
//import LocationList from '../components/Location/LocationList.js';
import LocationCard from '../components/Location/LocationCard.js';
import Location from '../components/Location/Location.js';

// -- Routes
import routes from '../routes';

// -- Helpers
import { getNavigationBySlug, getNavigationIndexFromView } from '../helpers.js';

const mapStateToProps = state => {
  return {
    topLocationsByEventType: state.topLocationsByEventType
  };
};

const mapDispatchToProps = dispatch => {
  return {
    storeCurrentNavigationIndex: navigationIndex => dispatch(storeCurrentNavigationIndex(navigationIndex)),
    storeTopLocationsByEventType: topLocationsByEventType => dispatch(storeTopLocationsByEventType(topLocationsByEventType))
  };
};

const styles = theme => ({
  rootGrid: {
    flexGrow: 1,
  },
  rootPaper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ConnectedHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topLocationsByEventType: props.topLocationsByEventType, // from Redux storage
      isSlideOpen: false,
      selectedLocation: {},

      XHRStateCheckerWithAPIFetchTopLocationsByEventType: null
    };

    this.handleRequestResult = this.handleRequestResult.bind(this);
    this.handleClickLocation = this.handleClickLocation.bind(this);

    // XHR
    //this.XHRStateCheckerWithAPIFetchTopLocationsByEventType = false;
  }

  componentDidMount() {
    console.info(`[${this.constructor.name}].componentDidMount`);
    this.props.storeCurrentNavigationIndex(getNavigationIndexFromView('Home', routes));  // redux storage

    if (!this.state.topLocationsByEventType.length){  // on charge la liste si cas déjà présente dans le store
      const withAPIFetchTopLocationsByEventType = Fetch(API.getTopLocationsByEventType());
      this.setState({ XHRStateCheckerWithAPIFetchTopLocationsByEventType: withAPIFetchTopLocationsByEventType(XHRStateChecker) });
    }
    else {
      console.info(`[${this.constructor.name}].componentDidMount topLocationsByEventType already loaded ...`);
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log(`[${this.constructor.name}].componentWillReceiveProps`, nextProps);
  }

  handleRequestResult(data){
    console.log(`[${this.constructor.name}].handleRequestResult`, data);
    if (typeof data.topLocationsByEventType !== "undefined"){
      this.setState({
         XHRStateCheckerWithAPIFetchTopLocationsByEventType: null,  // NB: sinon update infini !
         topLocationsByEventType: data.topLocationsByEventType
      });
      this.props.storeTopLocationsByEventType(data.topLocationsByEventType);  // Redux storage
    }
  }

  handleClickLocation(value){
    console.log(`[${this.constructor.name}].handleClickLocation`, value);
    this.setState({
       isSlideOpen: true,
       selectedLocation: value
    });
  }

  handleClickOpen = () => {
    this.setState({ isSlideOpen: true });
  };

  handleClose = () => {
    this.setState({ isSlideOpen: false });
  };

  getHomeLocationsList(locationsByEventTypes){
    if (locationsByEventTypes === null){
      throw new Error(`[${this.constructor.name}] locationsByEventTypes is null !`);
    }

    if (!locationsByEventTypes.length){
      return (<p>{APP_LANGUAGE.SEARCH_RESULT_NO_LOCATIONS}</p>)
    }

    return (  // ou https://codesandbox.io/s/rzmz5jnom
      <Grid container>
        {locationsByEventTypes.map((locationsByEventType, i) => {
          if (locationsByEventType.locations.length){
            return (
              <Grid item xs={12} sm={6} md={4} key={i} style={{ display: 'flex',  justifyContent: 'center',  alignItems: 'center' }}>
                <LocationCard
                  key={locationsByEventType.id}
                  eventType={locationsByEventType}
                  onClick={this.handleClickLocation}
                 />
              </Grid>
            )
          }
        } )}
      </Grid>
    );
  }

  render () {
    console.info(`[${this.constructor.name}].render props:`, this.props);
    console.info(`[${this.constructor.name}].render state:`, this.state);

    //const navigationEntry = getNavigationBySlug('home', routes);
    const { topLocationsByEventType } = this.state;

    const { classes } = this.props;
    const rootClassName = "view-container " + classes.rootGrid;

    // Utiliser compose ?
    // cf : https://lucasmreis.github.io/blog/simple-react-patterns/
    // https://gist.github.com/developit/5c7bae447e0456db907d53fb942f7b3b

    // -- Requête API
    const XHRStateCheckerWithAPIFetchTopLocationsByEventType = this.state.XHRStateCheckerWithAPIFetchTopLocationsByEventType ? this.state.XHRStateCheckerWithAPIFetchTopLocationsByEventType:null;

    return (
        <div className={rootClassName}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="spacer"></div>
                {/*<h1> {navigationEntry.title} </h1>*/}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Paper className={classes.rootPaper} elevation={1}>
                  <SearchForm onRequestResult={this.handleRequestResult} />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6} md={8}>
                  {
                    (XHRStateCheckerWithAPIFetchTopLocationsByEventType ? <XHRStateCheckerWithAPIFetchTopLocationsByEventType message={APP_LANGUAGE.API_TOP_LOCATIONS_BY_TYPE_LOADING} onRequestResult={this.handleRequestResult}/> : this.getHomeLocationsList(topLocationsByEventType))
                  }
              </Grid>
           </Grid>

           <Dialog
              fullScreen
              open={this.state.isSlideOpen}
              onClose={this.handleClose}
              TransitionComponent={Transition}>
            <Location onClose={this.handleClose} location={this.state.selectedLocation} filters={{period: "2w"}}/>
          </Dialog>
        </div>
      )
   }
}

ConnectedHome.propTypes = {
  classes: PropTypes.object.isRequired,
};

ConnectedHome = withStyles(styles)(ConnectedHome);

const Home = connect(mapStateToProps, mapDispatchToProps)(ConnectedHome);

export default Home;
