import React, { Component } from 'react';
import PropTypes from 'prop-types';

// -- Redux
//import { connect } from "react-redux";

// -- Language
import APP_LANGUAGE from '../../languages/fr.js';

// -- Material UI
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// -- Moment
import moment from 'moment';
import 'moment/locale/fr';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

const EventPanel = props => {

  //console.log(`[Location] props: `, props)

  const { classes, event } = props;

  // https://momentjs.com/
  const momentDateObj = moment(new Date(event.date));
  const eventTypeClassName = "eventType "+event.type.css;

  return (

      <ExpansionPanel className="event">
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}><span className={eventTypeClassName}></span> {event.title}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <dl className="eventDetails">
              <dt>{APP_LANGUAGE.EVENT_STAT_SUBTITLE}</dt>
              <dd>{event.subTitle}</dd>

              <dt>{APP_LANGUAGE.EVENT_STAT_MUSIC_TYPE}</dt>
              <dd>{event.type.name}</dd>

              <dt>{APP_LANGUAGE.EVENT_STAT_PRICE}</dt>
              <dd>{event.priceDetails.price ? event.priceDetails.price:APP_LANGUAGE.EVENT_STAT_PRICE_FREE}</dd>

              <dt>{APP_LANGUAGE.EVENT_STAT_DATE}</dt>
              <dd>{momentDateObj.format('L')}</dd>
            </dl>
          </ExpansionPanelDetails>
      </ExpansionPanel>
  );
}

EventPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventPanel);
