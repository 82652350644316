import React, { Component } from 'react';
import PropTypes from 'prop-types';

// -- Redux
import { connect } from "react-redux";

// -- Navigation
import routes from '../../routes';

// -- Language
import APP_LANGUAGE from '../../languages/fr.js';

// -- Helpers
import { redirectTo } from '../../helpers.js';

// -- Components
import logo from '../../img/logo.png';
import SavedLocations from '../../components/Location/SavedLocations.js';

// -- Material UI
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import HomeIcon from '@material-ui/icons/Home';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DeleteIcon from '@material-ui/icons/Delete';

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Drawer from '@material-ui/core/Drawer';

//import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';  // KO

const mapStateToProps = state => {
  return {
    currentNavigationIndex: state.currentNavigationIndex,
    savedLocations: state.savedLocations
   };
};

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ConnectedHeader extends Component {
  state = {
    // Dialog
    isSlideOpen: false,

    // Drawer
    top: false,
    left: false,
    bottom: false,
    right: false,
  }

  componentDidMount() {
    console.log(`[${this.constructor.name}] currentNavigationIndex:`, this.props.currentNavigationIndex)
  }

  handleClose = () => {
    this.setState({ isSlideOpen: false });
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  showSavedLocations = (e) => {
    console.log(`[${this.constructor.name}] showSavedLocations`, this.props.savedLocations)
    this.setState({
       isSlideOpen: true
    });
  }

  getSavedLocationsBtn = () => {
    //const matches = useMediaQuery('(min-width:600px)'); // KO
    //return <span>{`(min-width:600px) matches: ${matches}`}</span>;

    return <Button className="headerActionBtn" color="inherit" onClick={() => this.showSavedLocations()}>{APP_LANGUAGE.ACTION_SHOW_SAVED_LOCATIONS}</Button>
  }

  getSideMenu = (currentNavigationIndex) => {
    return (
      <div className="sideMenu">
        <List>
          {
            routes.map((obj, index) => {
              if (obj.menu){
                return (
                  <ListItem button key={index} onClick={() => this.handleClickMenu(obj) } selected={(index === currentNavigationIndex ? true:false)}>
                    <ListItemIcon>{this.getSideMenuIcon(obj.slug)}</ListItemIcon>
                    <ListItemText primary={obj.title} />
                  </ListItem>
                )
              }
              //return true
            })
          }
        </List>
        <Divider />
      </div>
    )
  }

  getSideMenuIcon = (slug) => {
    switch(slug){
      case 'home':
        return <HomeIcon />
      case 'saved-locations':
        return <LocationOnIcon />
      case 'reset-store':
        return <DeleteIcon />
      default:
        return null;
    }
  }

  handleClickMenu = (navigationEntry, params='') => {
    console.log(`[${this.constructor.name}] handleClickMenu`, navigationEntry)
    if (navigationEntry.path.length){
      redirectTo(navigationEntry.path+params);
    }
    else {
      console.log(`[${this.constructor.name}] handleClickMenu no path ...`)
      if (typeof this[navigationEntry.action] === "undefined"){
        throw new Error('No found action '+ navigationEntry.action +' in '+this.constructor.name)
      }
      this[navigationEntry.action].call();
    }
  }

  render () {

    const { classes, currentNavigationIndex } = this.props;

    return (
        <header className="header">

          <div className={classes.root}>
            <AppBar position="static">
              <Toolbar>
                <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.toggleDrawer('left', true)}>
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" className={classes.grow}>
                  {process.env.REACT_APP_NAME}
                </Typography>

                {/*routes.map((obj, index) => (
                  <Button color="inherit" key={index} href={obj.path+((process.env.REACT_APP_ENV === "development" && index === 0) ? "?resetStore":"")} active={(index === currentNavigationIndex ? 1:0)}>{obj.title}</Button>
                ))*/}

                {this.getSavedLocationsBtn()}
              </Toolbar>
            </AppBar>
          </div>

          {/*<a href={routes[0].path}><img src={logo} className="logo img-fluid" alt="logo" /></a>*/}
          <Dialog
             fullScreen
             open={this.state.isSlideOpen}
             onClose={this.handleClose}
             TransitionComponent={Transition}>
           <SavedLocations onClose={this.handleClose} />
         </Dialog>

         <Drawer open={this.state.left} onClose={this.toggleDrawer('left', false)}>
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('left', false)}
            onKeyDown={this.toggleDrawer('left', false)}
          >
            {this.getSideMenu(currentNavigationIndex)}
          </div>
        </Drawer>

        </header>
      );
   }
}

ConnectedHeader.propTypes = {
  classes: PropTypes.object.isRequired
};

ConnectedHeader = withStyles(styles)(ConnectedHeader);

const Header = connect(mapStateToProps)(ConnectedHeader);

export default Header;
