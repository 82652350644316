import React, { Component } from 'react';
import PropTypes from 'prop-types';

// -- Language
import APP_LANGUAGE from '../../languages/fr.js';

// -- Material UI
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

//import fakeImg from '../../img/event-types/thumbs/event-rock.jpg';
//import fakeImg from '../../img/contemplative-reptile.jpg';

// -- Helpers
import { truncate } from '../../helpers.js';

const styles = theme => ({
  card: {
    maxWidth: 300,
    height: 230,
    margin: '0px 10px 20px 10px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      margin: '10px 10px 10px 10px',
    },
  },
  media: {
    height: 140,
    opacity: 0.5
  },
  content: {
    padding:'16px 10px'
  },
  typoH2: {
    fontSize: '1.0rem',
    fontWeight: "bold"
  },
  typoH2Smaller: {
    fontSize: '0.8rem',
    fontWeight: "bold"
  },
  typoH3: {
    fontSize: '0.8rem',
  },
  typoP: {

  }
});

const getThumbImgSrc = cssClassName => {

  const ASSETS_URI = process.env.REACT_APP_ASSETS_URI;
  //const THUMB_IMG_DIR = '~@/img/event-types/thumbs/';
  //const THUMB_IMG_DIR = '../../img/event-types/thumbs/';  // KO avec require(img) ...
  // cf: https://dev.to/letsbsocial1/importing-images-in-react-84o
  // https://survivejs.com/webpack/loading/images/
  const THUMB_IMG_DIR = ASSETS_URI + process.env.REACT_APP_ASSETS_EVENT_TYPES_THUMBS_DIR;
  const THUMB_FILE_EXT = '.jpg';
  const FILE_SRC = THUMB_IMG_DIR + process.env.REACT_APP_EVENT_TYPE_IMG_PREFIX + cssClassName + THUMB_FILE_EXT;
  return FILE_SRC;
};

function LocationCard(props) {

  //console.log(`[LocationCard] props: `, props)
  const { classes, eventType } = props;

  if (!eventType.locations){
    throw new Error(`[LocationCard] no locations !`);
  }

  const img = getThumbImgSrc(eventType.css);
  const maxStdFontCharName = 25;
  const maxSmallerCharName = 35;
  const locationName = eventType.locations[0].name;
  const eventsNber = eventType.locations[0].eventsNber;
  const eventTypeName = eventType.name;

  if (!eventType.locations.length){
    throw new Error(`[LocationCard] locations is empty !`);
  }
  return (
    <Card className={classes.card}>
    {/*<Card className={classes.card} style={{ height: '300px' }}>*/}
      <CardActionArea button="true" onClick={() => props.onClick(eventType.locations[0])} className="locationCardActionArea">
        <CardMedia
          className={classes.media}
          component="img"
          image={img}
          alt={eventTypeName}
          title={eventTypeName}
        />
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h5" component="h2" className={(locationName.length > maxStdFontCharName ? classes.typoH2Smaller:classes.typoH2)}>
            {truncate(locationName, maxSmallerCharName)}
          </Typography>
          <Typography gutterBottom variant="h5" component="h2" className={classes.typoH3}>
            ({eventsNber} gigs)
          </Typography>
          <Typography component="p" className={classes.typoP}>
            {eventTypeName}
          </Typography>
        </CardContent>
      </CardActionArea>
      {/*<CardActions>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions>*/}
    </Card>
  );
}

LocationCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LocationCard);
