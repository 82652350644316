import {

  STORE_CURRENT_NAVIGATION_INDEX,
  STORE_EVENT_TYPES,
  STORE_LOCATION_ZONES,
  SET_SEARCH_FILTERS,
  STORE_FOUND_LOCATIONS,

  ADD_LOCATION,
  REMOVE_LOCATION,

  STORE_TOP_LOCATIONS_BY_EVENT_TYPE,

  INIT_PRESTATIONS,
  INIT_APPOINTMENT,
  INIT_ADDRESS } from "../constants/action-types.js";

  // -- API
  import API from '../../api/API.js';

const initialState = {
  currentNavigationIndex: 0,
  eventTypes: [],
  locationZones: [],

  searchFilters: {
      //'day':null,
      'idType':[],
      'idLocationZone':[],
      'kEvent':null,
      //'kLocation':null,
      'free':false,
      'jam':false,
      'clubbing':false,
      'geoMaxDistance':null,
      'period': API.DEFAULT_SEARCH_PERIOD,
      'idLocation':null,	// NB: from GET
      //'idEvent':null  // NB: from GET
  },

  foundLocations: null,
  savedLocations: [],

  topLocationsByEventType: [],


  address: '',
  appointment: null,  // ISO 8601 datetime
  currentPrestation: null,

  booking: null
};

/*Avoiding mutations in Redux :
Using concat(), slice(), and …spread for arrays
Using Object.assign() and …spread for objects
*/

const rootReducer = (state = initialState, action) => {
  switch (action.type) {

    case STORE_CURRENT_NAVIGATION_INDEX:
        return { ...state, currentNavigationIndex: action.payload};

    case STORE_EVENT_TYPES:
        return { ...state, eventTypes: action.payload};

    case STORE_LOCATION_ZONES:
        return { ...state, locationZones: action.payload};

    case SET_SEARCH_FILTERS:
        return { ...state, searchFilters: action.payload};

    case STORE_FOUND_LOCATIONS:
        return { ...state, foundLocations: action.payload};

    case ADD_LOCATION:
        return { ...state, savedLocations: state.savedLocations.concat(action.payload) };

    case REMOVE_LOCATION: // once only !

      let removed = false;
      let filteredLocations = [];
      state.savedLocations.forEach((location, index) => {
        if (!removed && (action.payload === location.id)){
          //console.log(`location found at ${index}`, location)
          filteredLocations = removeItem(state.savedLocations, index)
          removed = true;
        }
      });
      //console.log('filteredLocations :', filteredLocations)
      return { ...state, savedLocations:  filteredLocations};

    case STORE_TOP_LOCATIONS_BY_EVENT_TYPE:
        return { ...state, topLocationsByEventType: action.payload};

    case INIT_PRESTATIONS:
        return { ...state, prestations: []};
    case INIT_APPOINTMENT:
        return { ...state, appointment: null};
    case INIT_ADDRESS:
        return { ...state, address: ''};

    default:
      return state;
  }
};

// Remove an item inside items Array
const removeItem = (items, index) =>
  //items.slice(0, index-1).concat(items.slice(index, items.length))  // KO
  items.filter(function(value, i){
     return i !== index;
  });

export default rootReducer;
