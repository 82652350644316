import React, { Component } from 'react';
import PropTypes from 'prop-types';

// -- Language
import APP_LANGUAGE from '../../languages/fr.js';

// -- Material UI
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import Grid from '@material-ui/core/Grid';

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  grow: {
    flexGrow: 1,
  },
};

const handleClose = (props) => {
  console.log(`[DialogAppBar].handleClose`, props);

  // Passing data to parent component (SavedLocations)
  props.onClose();
};

const DialogAppBar = props => {

  //console.log(`[DialogAppBar] props: `, props)

  const { classes, title, buttons } = props;

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <Grid
          justify="space-between" // Add it here :)
          container
        >
          <Grid item>
            <IconButton color="inherit" onClick={() => handleClose(props)} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="inherit" className="appBarTitle">{title}</Typography>
          </Grid>
          <Grid item>
            {buttons}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

DialogAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func
};
DialogAppBar.defaultProps = {
  onClose: f => f,
  title: ""
}

export default withStyles(styles)(DialogAppBar);
