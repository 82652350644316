import React, { Component } from 'react';
//import PropTypes from 'prop-types';

// -- Redux
//import { connect } from "react-redux";

// -- Language
//import APP_LANGUAGE from '../../languages/fr.js';

// -- Material UI
import ListItem from '@material-ui/core/ListItem';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

// https://www.fullstackreact.com/p/using-presentational-and-container-components-with-redux/
// https://www.robinwieruch.de/react-pass-props-to-component/
const LocationListItem = props => (

  //console.log(`[Location] props: `, props)

  <ListItem button onClick={() => props.onClick(props.location)}>
    <ListItemText
      primary={props.location.name}
      secondary={props.location.eventsNber + " gig"+(props.location.eventsNber > 1 ? "s":"")}
    />
    <ListItemSecondaryAction>
      <IconButton aria-label="KeyboardArrowRight" >
        <KeyboardArrowRight />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
);

export default LocationListItem;
