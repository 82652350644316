import React from 'react';
import PropTypes from 'prop-types';

// -- Material UI
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// -- Language
import APP_LANGUAGE from '../languages/fr.js';

import APIDataResultHandler from '../api/APIDataResultHandler.js';

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

function CircularIndeterminate(props) {
  const { classes, message } = props;

  return (
    <div>
      <CircularProgress className={classes.progress} />
      <p>{message}</p>
    </div>
  );
}

CircularIndeterminate.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired
};
CircularIndeterminate.defaultProps = {
  message: APP_LANGUAGE.API_LOADING
}

const XHRStateChecker = props => {

  console.log(`[XHRStateChecker] props: `, props)

  if (props.error) {
    return <div className="error">{`An error occured! ${props.error}`}</div>;
  }

  if (props.isLoading) {
    const CircularIndeterminateWithStyles = withStyles(styles)(CircularIndeterminate);
    return <CircularIndeterminateWithStyles message={props.message} />;
    //return <div className="loading">Loading...</div>;
  }

  if (typeof props.data === "undefined"){
    throw new Error(`[XHRStateChecker] No data !`);
  }

  // Passing data to parent component (SearchForm)
  if (typeof(props.onRequestResult) === "function"){
    props.onRequestResult(props.data);
  }
  return <APIDataResultHandler data={props.data} />;
};

export default XHRStateChecker;
