import React, { Component } from 'react';

export default class Footer extends Component {
  state = {

  }

  render () {
      return (
        <footer className="card-footer text-muted">
           - Dev Leo @2019 -<br />{process.env.REACT_APP_ENV}
        </footer>
      )
   }
}
