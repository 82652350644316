export const STORE_CURRENT_NAVIGATION_INDEX = "STORE_CURRENT_NAVIGATION_INDEX";
export const STORE_EVENT_TYPES = "STORE_EVENT_TYPES";
export const STORE_LOCATION_ZONES = "STORE_LOCATION_ZONES";
export const SET_SEARCH_FILTERS = "SET_SEARCH_FILTERS";
export const STORE_FOUND_LOCATIONS = "STORE_FOUND_LOCATIONS";
export const ADD_LOCATION = "ADD_LOCATION";
export const REMOVE_LOCATION = "REMOVE_LOCATION";
export const STORE_TOP_LOCATIONS_BY_EVENT_TYPE = "STORE_TOP_LOCATIONS_BY_EVENT_TYPE";

export const INIT_PRESTATIONS = "INIT_PRESTATIONS";
export const INIT_APPOINTMENT = "INIT_APPOINTMENT";
export const INIT_ADDRESS = "INIT_ADDRESS";
