
// TODO : passer par une classe ES6 ?
// https://putaindecode.io/fr/articles/js/es2015/classes/

const API = {

  BASE_URL: process.env.REACT_APP_API_URL,

  // Support de l'API HTML5 geoLocation
	GEOLOCATION_ENABLED: navigator.geolocation ? true:false,

  DEFAULT_SEARCH_PERIOD: "2w", // période par défaut
  SEARCH_PERIODS: [
    {
      id: "2w",
      name: "15j"
    },
    {
      id: "1m",
      name: "1 mois"
    },
    {
      id: "2m",
      name: "2 mois"
    },
    {
      id: "3m",
      name: "3 mois"
    }],

  BOOLEAN_FILTERS: [
    {
      id: "free",
      name: "Concert gratuit"
    },
    {
      id: "jam",
      name: "Jam session, \"boeufs\""
    },
    {
      id: "clubbing",
      name: "Clubbing party"
    }],
  MAP_RATIO: 0.5,   // hauteur de la carte en fonction de window

  filters: {
      //'day':null,
      'idType':null,
      'idLocationZone':null,
      'kEvent':null,
      //'kLocation':null,
      'free':0,
      'jam':0,
      'clubbing':0,
      'geoMaxDistance':null,
      'period': "2w",
      'idLocation':null,	// NB: from GET
      //'idEvent':null  // NB: from GET
  },

  // met à jour tous les filtres (lancement d'une nouvelle recherche)
  setFilters: function(filters){
    this.filters = filters;
  },

  // met à jour un filtre
  setFilter: function(filter, value){
      if (typeof this.filters[filter] !== "undefined") this.filters[filter] = value;
  },

  // renvoir la valeur d'un filtre
  getFilter: function(filter){
	   return (typeof this.filters[filter] !== "undefined") ? this.filters[filter]:null;
  },

  getTimestamp: function(){
    return Math.floor(Date.now()/1000);
  },

  getFilters: function(){
    return this.filters;
  },

  formatFilters: function(filters){

    const filtersCopy = Object.assign({}, filters);

    // Cast boolean filters : true > 1
    for (let filter in filtersCopy){
      if (typeof filtersCopy[filter] === "boolean"){
        filtersCopy[filter] = filtersCopy[filter] ? 1:0;
      }
    }
    return filtersCopy;
  },

  getParameters: function(action, filters){

    if (typeof action === "undefined"){
      throw new Error(`[${this.constructor.name}].getParameters: no action !`);
    }

    if (typeof filters !== "undefined"){
      filters = this.formatFilters(filters);
    }

    const userGeoPosition = {		// coordonnées en cours du user > Redux
        'latitude': null,
        'longitude': null
    };

    const offset = 0;
    const limit = 30;
    const origin = null;

    const parameters = {
  	    'action': action,
  	    'offset': offset,
  	    'limit': limit, // this.getLimit(),
  	    'filters': (typeof filters !== "undefined" && filters) ? JSON.stringify(filters) : null,
  	    'origin': escape(origin),
  	    //'token': app.options.token,
  	    //'language': app.options.language,
  	    //'seo': app.seo ? 1:null,
  	    'geolocationEnabled': this.GEOLOCATION_ENABLED ? 1:0,
  	    'userGeoPosition': JSON.stringify(userGeoPosition),
  	    'debug': process.env.REACT_APP_ENV === "development" ? 1:0
  	};
    // http://dev-leo.net/FindAGig/api/ajax.php?action=getAppGlobalFilters&filters={}&origin=null&geolocationEnabled=1&userGeoPosition={"latitude":null,"longitude":null}&debug=1&_=1547717782337

    console.log(`[${this.constructor.name}].getParameters: `, parameters)

    return parameters;
  },

  serialize:  function(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  },

  // -- Routes
  getAppGlobalFilters: function(){
    const action = "getAppGlobalFilters";
    return this.BASE_URL + "?" + this.serialize(this.getParameters(action));
  },
  getTopLocationsByEventType: function(){
    const action = "getTopLocationsByEventType";
    return this.BASE_URL + "?" + this.serialize(this.getParameters(action));
  },

  getLocations: function(filters){
    const action = "getLocations";
    return this.BASE_URL + "?" + this.serialize(this.getParameters(action, filters));
  },

  getLocationStats: function(filters, idLocation){
    if (typeof filters === "undefined"){
      throw new Error(`[${this.constructor.name}].getLocationStats: no filters !`);
    }
    if (typeof idLocation !== "undefined"){
      filters.idLocation = idLocation;
    }
    const action = "getLocationStats";
    return this.BASE_URL + "?" + this.serialize(this.getParameters(action, filters));
  },

  getLocationEvents: function(filters, idLocation){
    if (typeof filters === "undefined"){
      throw new Error(`[${this.constructor.name}].getLocationEvents: no filters !`);
    }
    if (typeof idLocation !== "undefined"){
      filters.idLocation = idLocation;
    }
    const action = "getEvents";
    return this.BASE_URL + "?" + this.serialize(this.getParameters(action, filters));
  }

}

export default API;
