// https://www.npmjs.com/package/google-maps-react
// https://fullstackreact.github.io/basic

import React, { Component } from 'react';

import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';

// -- Language
import APP_LANGUAGE from '../../languages/fr.js';

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {}
    };
    this.onMapClicked = this.onMapClicked.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
  }

  static defaultProps = {
    style: {
      width: '100%',
      height: '50vh',
      position: 'relative'
    },
    center: { // default Paris
      lat: 48.8534,
      lng: 2.3488
    },
    zoom: 11
  };

  getLocationMarkers(locations){
      const locationMarkers = [];
      locations.map(location => {
        const coords = location.coords.split(",");
        if (typeof coords[0] !== "undefined" && typeof coords[1] !== "undefined"){
          locationMarkers.push(this.getLocationMarker(parseFloat(coords[0]), parseFloat(coords[1]), location));
        }
      });
      return locationMarkers;
  }

  getLocationMarker = (lat, lng, location) => {
    //console.info(`[${this.constructor.name}].getLocationMarker`, lat, lng, location);
    return (
      <Marker
        key={location.id}
        title={location.name}
        name={location.name}
        position={{lat: lat, lng: lng}}
        location_id={location.id}
        location_address={location.address}
        location_eventsNber={location.eventsNber}
        onClick={this.onMarkerClick}
      />);
  }

  getPoints(locations){
    const points = [];
    locations.map(location => {
      const coords = location.coords.split(",");
      if (typeof coords[0] !== "undefined" && typeof coords[1] !== "undefined"){
        points.push({lat: parseFloat(coords[0]), lng: parseFloat(coords[1])});
      }
    });
    return points;
  }

  onMapClicked(mapProps, map, clickEvent) {
    //console.info(`[${this.constructor.name}].onMapClicked`, mapProps, map, clickEvent);
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  }
  onMarkerClick(props, marker, e) {
    console.info(`[${this.constructor.name}].onMarkerClick`, props, marker, e);
    this.setState({
      selectedPlace: props, 
      activeMarker: marker,
      showingInfoWindow: true
    });
  }

  onInfoWindowOpen(){
    console.info(`[${this.constructor.name}].onInfoWindowOpen`, arguments);
  }
  onInfoWindowClose(){
    console.info(`[${this.constructor.name}].onInfoWindowClose`, arguments);
  }

  render() {
    console.info(`[${this.constructor.name}].render`, this.props);

    const points = this.getPoints(this.props.locations);
    const bounds = new this.props.google.maps.LatLngBounds();
    for (let i = 0; i < points.length; i++) {
      bounds.extend(points[i]);
    }

    return (

      <div style={this.props.style} className={'map'}>
        <Map
          google={this.props.google}
          style={this.props.style}
          zoom={this.props.zoom}
          initialCenter={this.props.center}
          onClick={this.onMapClicked}
          bounds={bounds}
        >

          {this.getLocationMarkers(this.props.locations)}

          <InfoWindow
            onOpen={this.onInfoWindowOpen}
            onClose={this.onInfoWindowClose}
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}>
            <div className="infoWindow">
              <h1>{this.state.selectedPlace.name}</h1>
              <p className="address">{this.state.selectedPlace.location_address}</p>
              <p className="gigs">{this.state.selectedPlace.location_eventsNber} {APP_LANGUAGE.EVENT_NAME+(this.state.selectedPlace.location_eventsNber > 0 ? "s":"")}</p>
            </div>
          </InfoWindow>

        </Map>
      </div>
    );
  }
}

const LoadingContainer = (props) => (
  <div>Fancy loading container!</div>
)

export default GoogleApiWrapper({
  apiKey: (process.env.REACT_APP_GMAP_KEY),
  LoadingContainer: LoadingContainer
})(MapContainer)
