import React, { Component } from 'react';
import PropTypes from 'prop-types';

// -- Material UI
import { withStyles } from '@material-ui/core/styles';
//import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const styles = theme => ({
  root: {

  }
});

class SwitchLabelWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.state[props.name] = props.isChecked; // already selected values
  }

  componentDidMount() {
    //console.info(`[${this.constructor.name}].componentDidMount`, this.props);
  }
  componentWillReceiveProps(nextProps) {
    //console.log(`[${this.constructor.name}].componentWillReceiveProps`, this.state.selectedValue, nextProps.selectedValue);
  }
  /*
  shouldComponentUpdate(nextProps, nextState) {
    //console.log(`[${this.constructor.name}].shouldComponentUpdate`, nextProps, nextState);
    if (nextProps.isChecked !== nextState[nextProps.name]){ // permet de forcer le render() si reset
      this.setState({ [nextProps.name]: nextProps.isChecked })
    }
    return true;
  }
  */
/*
  handleChange = event => {
    console.log(`[${this.constructor.name}].handleChange`, event.target.value, event.target.checked);
    this.setState({ checked: event.target.checked });

    // Passing data to parent component
    //this.props.onSelect(event.target.value, event.target.checked);
  };
*/
  handleChange = name => event => {
    //console.log(`[${this.constructor.name}].handleChange`, event.target.value, event.target.checked);
    //this.setState({ [name]: event.target.checked });  // NB: component will use props from SearchForm vs local state

    // Passing data to parent component
    this.props.onSelect(event.target.value, event.target.checked);
  };

  render() {
    //console.info(`[${this.constructor.name}].render`, this.props, this.state);
    // https://reactjs.org/docs/faq-functions.html

    const { classes, label, name, isChecked } = this.props;
    //const isChecked = this.state[name];

    return (
      <div className={classes.root}>
        <FormControlLabel
            control={
              <Switch
                checked={isChecked}
                onChange={this.handleChange(name)}
                value={name}
                color="primary"
              />
            }
            label={label}
          />
      </div>
    );
  }
}

SwitchLabelWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onSelect: PropTypes.func
};
SwitchLabelWrapper.defaultProps = {
  isChecked: false,
  onSelect: f => f
}

export default withStyles(styles)(SwitchLabelWrapper);
