import React from 'react'

const Fetch = url => Component => class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      data: []
    }
  }

  componentDidMount() {
    console.info(`[Fetch] url: `+ url);

    fetch(url)
      .then(response => {
        if (response.ok) {
            console.info(`[Fetch] response: `, response);
            return response.json();
          } else {
            throw new Error(`[Fetch] Something went wrong ...`);
          }
      })
      .then(
        json => {
          console.info(`[Fetch] json: `, json);
    			this.setState({ data: json, isLoading: false });
    		},
        error => this.setState({ isLoading: false, error })
      )
      .catch(error => {
        this.setState({error: error, isLoading: false})
        console.error(`[Fetch] Error happened during fetching : `+ this.state.error);
      });
  }

  render() {
    return <Component {...this.props} {...this.state} />
  }
}

export default Fetch;
