import React, { Component } from 'react';

// -- Redux
import { connect } from "react-redux";
import { storeEventTypes, storeLocationZones, storeFoundLocations  } from "../redux/actions/actions.js";

const mapDispatchToProps = dispatch => {
  return {
    storeEventTypes: eventTypes => dispatch(storeEventTypes(eventTypes)),
    storeLocationZones: locationZones => dispatch(storeLocationZones(locationZones)),

    storeFoundLocations: locations => dispatch(storeFoundLocations(locations)),
  };
};

class ConnectedAPIDataResultHandler extends Component {
  constructor() {
    super();
    this.state = {

    };
  }

  componentDidMount() {
    //console.info(`[${this.constructor.name}].componentDidMount props:`, this.props);

    // -- 1st App load
    if (typeof this.props.data.eventTypes !== "undefined"){
      console.log(`[${this.constructor.name}].componentDidMount eventTypes: `, this.props.data.eventTypes);
      this.props.storeEventTypes(this.props.data.eventTypes);
    }
    if (typeof this.props.data.locationZones !== "undefined"){
      console.log(`[${this.constructor.name}].componentDidMount locationZones: `, this.props.data.locationZones);
      this.props.storeLocationZones(this.props.data.locationZones);
    }

    else if (typeof this.props.data.topLocationsByType !== "undefined"){
      console.log(`[${this.constructor.name}].componentDidMount topLocationsByType: `, this.props.data.topLocationsByType);
      //this.props.storeTopLocationsByType(this.props.data.topLocationsByType);  // à stocker en local ?
    }

    // -- getLocations
    else if (typeof this.props.data.locations !== "undefined"){
        console.log(`[${this.constructor.name}].componentDidMount locations: `, this.props.data.locations);

        // Stockage en local des locations
        this.props.storeFoundLocations(this.props.data.locations);
    }

    // -- getLocationEvents
    else if (typeof this.props.data.events !== "undefined"){
        console.log(`[${this.constructor.name}].componentDidMount events: `, this.props.data.events);
    }

    // -- getLocationStats
    else if (typeof this.props.data.stats !== "undefined"){
        console.log(`[${this.constructor.name}].componentDidMount stats: `, this.props.data.stats);
    }
  }

  render(){
    //console.info(`[${this.constructor.name}].render props:`, this.props);
    return true;
  }
}

const APIDataResultHandler = connect(null, mapDispatchToProps)(ConnectedAPIDataResultHandler);

export default APIDataResultHandler;
