import {
  STORE_CURRENT_NAVIGATION_INDEX,
  STORE_EVENT_TYPES,
  STORE_LOCATION_ZONES,
  SET_SEARCH_FILTERS,
  STORE_FOUND_LOCATIONS,

  ADD_LOCATION,
  REMOVE_LOCATION,

  STORE_TOP_LOCATIONS_BY_EVENT_TYPE,

  INIT_PRESTATIONS,
  INIT_APPOINTMENT,
  INIT_ADDRESS  } from "../constants/action-types.js";

export const storeCurrentNavigationIndex = navigationIndex => ({ type: STORE_CURRENT_NAVIGATION_INDEX, payload: navigationIndex });
export const storeEventTypes = eventTypes => ({ type: STORE_EVENT_TYPES, payload: eventTypes });
export const storeLocationZones = locationZones => ({ type: STORE_LOCATION_ZONES, payload: locationZones });
export const setSearchFilters = filters => ({ type: SET_SEARCH_FILTERS, payload: filters });
export const storeFoundLocations = locations => ({ type: STORE_FOUND_LOCATIONS, payload: locations });
export const addLocation = location => ({ type: ADD_LOCATION, payload: location });
export const removeLocation = locationId => ({ type: REMOVE_LOCATION, payload: locationId });
export const storeTopLocationsByEventType = topLocationsByEventType => ({ type: STORE_TOP_LOCATIONS_BY_EVENT_TYPE, payload: topLocationsByEventType });

export const initPrestations = () => ({ type: INIT_PRESTATIONS });
export const initAddress = () => ({ type: INIT_ADDRESS });
export const initAppointment = () => ({ type: INIT_APPOINTMENT });
