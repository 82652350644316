import React, { Component } from 'react';

// -- BrowserRouter
import  {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { HashRouter } from 'react-router-dom'

// -- API
import API from './api/API.js';

// -- Navigation
import routes from './routes.js';
import { getNavigationBySlug } from './helpers.js';

// -- Components
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import ResetStore from './redux/components/ResetStore';

// -- Views
import Home from './views/Home';
import Locations from './views/Locations';

// -- Styles
import './styles/App.css';
import './styles/sprites.css';

// Custom Environment Variables
console.log('ENV:', process.env);

// Test API
console.log(API.getAppGlobalFilters());

// -- Routes / Views
const HomeView = () => (
  <div className="view">
    <Header />
    <Home />
    <Footer />
  </div>
);
const LocationsView = () => (
  <div className="view">
    <Header />
    <Locations />
    <Footer />
  </div>
)
const ResetStoreView = () => (
  <div className="view">
    <Header />
    <ResetStore />
    <Footer />
  </div>
)
const NoMatchView = () => ( // 404
  <div className="view">
    <Header />
    <h3>
      Error 404 : No match view for <code>{window.location.pathname}</code>
    </h3>
    <Footer />
  </div>
)

class App extends Component {

  componentDidMount() {
    console.info(`[${this.constructor.name}].componentDidMount`);
  }

  render() {
    return (

      <HashRouter>
        <div className="App">
          <Switch>
            <Route path={getNavigationBySlug('home', routes).path} exact={true} component={HomeView} />
            <Route path={getNavigationBySlug('locations', routes).path} exact={true} component={LocationsView} />
            <Route path={getNavigationBySlug('reset-store', routes).path} exact={true} component={ResetStoreView} />
            <Route component={NoMatchView}/>
          </Switch>
        </div>
      </HashRouter>
    );
  }
}

export default App;
