import React, { Component } from 'react';
import PropTypes from 'prop-types';

// -- Redux
import { connect } from "react-redux";
import { storeCurrentNavigationIndex  } from "../redux/actions/actions.js";

// -- Language
import APP_LANGUAGE from '../languages/fr.js';

// -- API
//import API from '../api/API.js';
//import Fetch from '../hoc/Fetch.js';
//import XHRStateChecker from '../api/XHRStateChecker.js';

// -- Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

// -- Components
import SearchForm from '../components/Form/SearchForm.js';
import LocationListItem from '../components/Location/LocationListItem.js';
import Map from '../components/Map/GMap.js';
//import Map from '../components/Map/Map.js';
import Location from '../components/Location/Location.js';

// -- Routes
import routes from '../routes';

// -- Helpers
import { getNavigationBySlug, getNavigationIndexFromView } from '../helpers.js';

const mapStateToProps = state => {
  return {
    foundLocations: state.foundLocations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    storeCurrentNavigationIndex: navigationIndex => dispatch(storeCurrentNavigationIndex(navigationIndex))
  };
};

const styles = theme => ({
  rootGrid: {
    flexGrow: 1,
  },
  rootPaper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  }
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ConnectedLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foundLocations: props.foundLocations, // from Redux storage
      isSlideOpen: false,
      selectedLocation: {}
    };

    this.handleRequestResult = this.handleRequestResult.bind(this);
    this.handleClickLocation = this.handleClickLocation.bind(this);
  }

  componentDidMount() {
    console.info(`[${this.constructor.name}].componentDidMount`);
    this.props.storeCurrentNavigationIndex(getNavigationIndexFromView('Locations', routes));  // redux storage
  }

  componentWillReceiveProps(nextProps) {
    console.log(`[${this.constructor.name}].componentWillReceiveProps`, nextProps);
  }

  handleRequestResult(data){
    console.log(`[${this.constructor.name}].handleRequestResult`, data);
    if (typeof(data.locations) !== "undefined"){
      this.setState({foundLocations: (data.locations !== null ? data.locations : []) });
    }
  }

  handleClickLocation(value){
    console.log(`[${this.constructor.name}].handleClickLocation`, value);
    this.setState({
       isSlideOpen: true,
       selectedLocation: value
    });
  }

  handleClickOpen = () => {
    this.setState({ isSlideOpen: true });
  };

  handleClose = () => {
    this.setState({ isSlideOpen: false });
  };

  getLocationsList(locations){
    if (locations === null){
      throw new Error(`[${this.constructor.name}] locations is null !`);
    }

    if (!locations.length){
      return (<p>{APP_LANGUAGE.SEARCH_RESULT_NO_LOCATIONS}</p>)
    }
    return (
      <List>
        {locations.map(location => (
          <LocationListItem key={location.id} location={location} onClick={this.handleClickLocation}/>
        ))}
      </List>
    );
  }

  render () {
    console.info(`[${this.constructor.name}].render props:`, this.props);
    console.info(`[${this.constructor.name}].render state:`, this.state);

    //const navigationEntry = getNavigationBySlug('locations', routes);
    const { foundLocations } = this.state;

    const { classes } = this.props;
    const rootClassName = "view-container " + classes.rootGrid;

      return (
        <div className={rootClassName}>
          <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="spacer"></div>
                {/*<h1> {navigationEntry.title} </h1>*/}
              </Grid>

              <Grid item xs={12}>
                <Map locations={foundLocations}/>
              </Grid>

              <Grid item xs={12} sm={6} md={4} className="searchFormGridItem">
                <Paper className={classes.rootPaper} elevation={1}>
                  <SearchForm onRequestResult={this.handleRequestResult} />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6} md={8} className="locationsListGridItem">
                <Paper className={classes.rootPaper} elevation={1}>
                  {this.getLocationsList(foundLocations)}
                </Paper>
              </Grid>

           </Grid>

           <Dialog
              fullScreen
              open={this.state.isSlideOpen}
              onClose={this.handleClose}
              TransitionComponent={Transition}>
            <Location onClose={this.handleClose} location={this.state.selectedLocation}/>
          </Dialog>
        </div>
      )
   }
}

ConnectedLocations.propTypes = {
  classes: PropTypes.object.isRequired,
};

ConnectedLocations = withStyles(styles)(ConnectedLocations);

const Locations = connect(mapStateToProps, mapDispatchToProps)(ConnectedLocations);

export default Locations;
