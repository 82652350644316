import React, { Component } from 'react';
import PropTypes from 'prop-types';

// -- Redux
//import { connect } from "react-redux";

// -- Language
import APP_LANGUAGE from '../../languages/fr.js';

// -- Material UI
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';

// https://material.io/tools/icons/?icon=local_bar&style=baseline
import Avatar from '@material-ui/core/Avatar';
import PhoneIcon from '@material-ui/icons/Phone';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import LanguageIcon from '@material-ui/icons/Language';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';

import Divider from '@material-ui/core/Divider';


const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
});

const getExtLink = (url) => {
  return (
    <Link href={"http://"+url} className="extLink" target="_blank" rel="noreferrer">
      {url}
    </Link>
  )
}
const webSiteURL = (location) => {
  if (typeof location.website !== "undefined" && location.website !== ""){

    //const href = "<a href='"+location.website+"' target='_blank'>"+location.website+"</a>";

    return (
      <React.Fragment>
        <ListItem>
          <Avatar>
            <LanguageIcon />
          </Avatar>
          <ListItemText primary={APP_LANGUAGE.EVENT_STAT_WEBSITE} secondary={getExtLink(location.website)} />
        </ListItem>
        <li>
          <Divider variant="inset" />
        </li>
      </React.Fragment>
    )
  }
}
const phoneNber = (location) => {
  if (typeof location.phoneNber !== "undefined" && location.phoneNber !== ""){

    return (
      <React.Fragment>
        <ListItem>
          <Avatar>
            <PhoneIcon />
          </Avatar>
          <ListItemText primary={APP_LANGUAGE.EVENT_STAT_PHONE_NBER} secondary={location.phoneNber} />
        </ListItem>
        <li>
          <Divider variant="inset" />
        </li>
      </React.Fragment>
    )
  }
}

const avgPrice = (stats) => {
  if (typeof stats.website !== "undefined" && stats.avgPrice !== ""){

    return (
      <React.Fragment>
        <ListItem>
          <Avatar>
            <EuroSymbolIcon />
          </Avatar>
          <ListItemText primary={APP_LANGUAGE.EVENT_STAT_AVG_PRICE} secondary={stats.avgPrice} />
        </ListItem>
        <li>
          <Divider variant="inset" />
        </li>
      </React.Fragment>
    )
  }
}
const avgBeerPrice = (stats) => {
  if (typeof stats.avgBeerPrice !== "undefined" && stats.avgBeerPrice !== ""){

    return (
      <React.Fragment>
        <ListItem>
          <Avatar>
            <LocalBarIcon />
          </Avatar>
          <ListItemText primary={APP_LANGUAGE.EVENT_STAT_AVG_BEER_PRICE} secondary={stats.avgBeerPrice + " €"} />
        </ListItem>
        <li>
          <Divider variant="inset" />
        </li>
      </React.Fragment>
    )
  }
}

const LocationStats = props => {

  console.log(`[LocationStats] props: `, props)

  const { classes, stats, location } = props;

  return (

    <List className={classes.root}>

      {webSiteURL(location)}
      {phoneNber(location)}

      {avgPrice(stats)}
      {avgBeerPrice(stats)}

    </List>
  );
}

LocationStats.propTypes = {
  classes: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withStyles(styles)(LocationStats);
