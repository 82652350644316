import React, { Component } from 'react';
import PropTypes from 'prop-types';

// -- Redux
import { connect } from "react-redux";
import { setSearchFilters  } from "../../redux/actions/actions.js";

// -- API
import API from '../../api/API.js';
import Fetch from '../../hoc/Fetch.js';
import XHRStateChecker from '../../api/XHRStateChecker.js';

// -- Language
import APP_LANGUAGE from '../../languages/fr.js';

// -- Material UI
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';

// -- Components
import MultipleSelectWrapper from '../Form/MultipleSelectWrapper.js';
import RadioButtonsGroupWrapper from '../Form/RadioButtonsGroupWrapper.js';
import SwitchLabelWrapper from '../Form/SwitchLabelWrapper.js';
import ButtonWrapper from '../Form/ButtonWrapper.js';

// -- Routes
import routes from '../../routes';

// -- Helpers
import { getNavigationBySlug, getNavigationByIndex, getNavigationIndexFromView, redirectTo } from '../../helpers.js';

const mapStateToProps = state => {
  return {
    currentNavigationIndex: state.currentNavigationIndex,
    locationZones: state.locationZones,
    eventTypes: state.eventTypes,
    searchFilters: state.searchFilters
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSearchFilters: filters => dispatch(setSearchFilters(filters))
  };
};

class ConnectedSearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchRequest: false,

      locationZones: props.locationZones,
      eventTypes: props.eventTypes,

      idLocationZone: props.searchFilters.idLocationZone,
      idType: props.searchFilters.idType,
      period: props.searchFilters.period, //API.DEFAULT_SEARCH_PERIOD,

      searchFilters: props.searchFilters, // from Redux storage
/*
      filters: {
        idLocationZone: [],
        idType: [],
        period: API.DEFAULT_SEARCH_PERIOD
      }
*/
    }

    API.BOOLEAN_FILTERS.map(filter => {
      return this.state[filter.id] = props.searchFilters[filter.id]; // init
    });

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormReset = this.handleFormReset.bind(this);

    this.handleSelectEventTypes = this.handleSelectEventTypes.bind(this);
    this.handleSelectLocationZones = this.handleSelectLocationZones.bind(this);
    this.handleDeleteEventTypes = this.handleDeleteEventTypes.bind(this);
    this.handleDeleteLocationZones = this.handleDeleteLocationZones.bind(this);

    this.handleSelectSearchPeriod = this.handleSelectSearchPeriod.bind(this);
    this.handleSelectSwitchLabel = this.handleSelectSwitchLabel.bind(this);
    this.handleRequestResult = this.handleRequestResult.bind(this);
  }

  componentDidMount() {
    console.log(`[${this.constructor.name}].componentDidMount`, this.props, this.state);

    // -- Store already loaded locally > set by constructor
    /*
    if (this.props.eventTypes !== null){
      this.setState({ eventTypes: this.props.eventTypes });
    }
    if (this.props.locationZones !== null){
      this.setState({ locationZones: this.props.locationZones });
    }
    */
  }

  componentWillReceiveProps(nextProps) {
    console.log(`[${this.constructor.name}].componentWillReceiveProps`, nextProps);

    // -- Store not already loaded locally
    if ( typeof(nextProps.eventTypes) !== "undefined" && nextProps.eventTypes !== null){
      this.setState({ eventTypes: nextProps.eventTypes });
    }
    if (typeof(nextProps.locationZones) !== "undefined" && nextProps.locationZones !== null){
      this.setState({ locationZones: nextProps.locationZones });
    }

    // -- New searchFilters already stored by Redux
    if ( typeof(nextProps.searchFilters) !== "undefined"){
      this.setState({ searchFilters: nextProps.searchFilters });
    }
  }

  getSearchFiltersFromState(){
    const filters = {
      idType: this.state.idType,
      idLocationZone: this.state.idLocationZone,
      period: this.state.period
    }
    API.BOOLEAN_FILTERS.map(filter => {
      return filters[filter.id] = this.state[filter.id];
    });
    return filters;
  }

  handleFormSubmit(e){
    console.log(`[${this.constructor.name}].handleFormSubmit state:`, this.state)

    //this.setState({ 'filters': {'test': true} });

    // Redux storage : update searchFilters
    const filters = this.getSearchFiltersFromState();
    this.props.setSearchFilters(filters);

    this.setState({searchRequest: true});

    //console.log(`[${this.constructor.name}].handleFormSubmit searchFilters:`, this.props.searchFilters);  // NB : accessible via componentWillReceiveProps
  }

  handleFormReset(e){
    console.log(`[${this.constructor.name}].handleFormReset`);
    this.setState({ idType: [] });
    this.setState({ idLocationZone: [] });
    this.setState({ period: API.DEFAULT_SEARCH_PERIOD });

    //this.setState({ 'filters': {'idType':[]} });
    //this.setState({ 'filters': {'idLocationZone':[]} });
    //this.setState({ 'filters': {'period': API.DEFAULT_SEARCH_PERIOD} });

    API.BOOLEAN_FILTERS.map(filter => {
      return this.setState({ [filter.id]: false});
    });

    // TODO : Redux update ? (should update locations List ...)
  }

  handleSelectEventTypes(value){
    //console.log(`[${this.constructor.name}].handleSelectEventTypes`, value);
    this.setState({ idType: value });
    //this.setState({ 'filters': {'idType': value} });
  }
  handleDeleteEventTypes(value){
    console.log(`[${this.constructor.name}].handleDeleteEventTypes`, value);
    this.setState({ idType: value });
  }

  handleSelectLocationZones(value){
    //console.log(`[${this.constructor.name}].handleSelectLocationZones`, value);
    this.setState({ idLocationZone: value });
    //this.setState({ 'filters': {'idLocationZone': value} });
  }
  handleDeleteLocationZones(value){
    console.log(`[${this.constructor.name}].handleDeleteLocationZones`, value);
    this.setState({ idLocationZone: value });
  }

  handleSelectSearchPeriod(value){
    console.log(`[${this.constructor.name}].handleSelectSearchPeriod`, value);
    this.setState({ period: value });
    //this.setState({ filters: {...this.state.filters, '2m' } }); // KO
    //this.setState({ 'filters': {'period': value} });  // KO
    //console.log(`[${this.constructor.name}].handleSelectSearchPeriod after ...`, this.state);
  }
  handleSelectSwitchLabel(name, isChecked){
    //console.log(`[${this.constructor.name}].handleSelectSwitchLabel`, name, isChecked);
    this.setState({ [name]: isChecked });
  }

  handleRequestResult(data){
    console.log(`[${this.constructor.name}].handleRequestResult`, data);
    this.setState({searchRequest: false});  // init

    const currentNavigationEntry = getNavigationByIndex(this.props.currentNavigationIndex, routes);
    //console.log(`[${this.constructor.name}].handleRequestResult`, currentNavigationEntry);
    if (currentNavigationEntry.slug !== 'locations'){   // Redirect to LocationsView if different
      const navigationEntry = getNavigationBySlug('locations', routes);
      redirectTo(navigationEntry.path);
    }

    // If passing data to parent component (Home|Locations) ...
    if (typeof(this.props.onRequestResult) === "function"){
      this.props.onRequestResult(data);
    }
  }

  render () {

      console.log(`[${this.constructor.name}].render`, this.state);

      const { locationZones, eventTypes, idType, idLocationZone, period, searchFilters, searchRequest } = this.state;
      //const { classes } = this.props;

      // -- Requête API
      let XHRStateCheckerWithAPIFetch = null;
      if (searchRequest) {  // new API request

        const URL = API.getLocations(searchFilters);
        console.log(`[${this.constructor.name}].render URL:`, searchFilters, URL);

        const withAPIFetch = Fetch(URL);
        XHRStateCheckerWithAPIFetch = withAPIFetch(XHRStateChecker);  // OK
      }

      if (locationZones.length && eventTypes.length){

        return (

            <form className="searchForm" onSubmit={() => this.handleFormSubmit()}>

              <Typography variant="h6" gutterBottom>{APP_LANGUAGE.FORM_TITLE}</Typography>
              {
                (XHRStateCheckerWithAPIFetch ? <XHRStateCheckerWithAPIFetch onRequestResult={this.handleRequestResult}/> : null)
              }

              <fieldset className="selectGroup">
                <MultipleSelectWrapper data={eventTypes} label={APP_LANGUAGE.FORM_SELECT_EVENT_TYPE} onSelect={this.handleSelectEventTypes} onDelete={this.handleDeleteEventTypes} ids={idType} />
                <MultipleSelectWrapper data={locationZones} label={APP_LANGUAGE.FORM_SELECT_LOCATION_ZONE} onSelect={this.handleSelectLocationZones}  onDelete={this.handleDeleteLocationZones} ids={idLocationZone} />
              </fieldset>

              <fieldset className="radioButtonGroup">
                <RadioButtonsGroupWrapper data={API.SEARCH_PERIODS} label={APP_LANGUAGE.FORM_SEARCH_PERIOD} defaultValue={API.DEFAULT_SEARCH_PERIOD} onSelect={this.handleSelectSearchPeriod} selectedValue={period} />
              </fieldset>

              <fieldset className="switchGroup">
                <FormGroup row className="center">
                  {API.BOOLEAN_FILTERS.map(filter => (
                    <SwitchLabelWrapper name={filter.id} label={filter.name} key={filter.id} onSelect={this.handleSelectSwitchLabel} isChecked={this.state[filter.id]} />
                  ))}
                </FormGroup>
              </fieldset>
              <fieldset className="buttonGroup">
                <ButtonWrapper label={APP_LANGUAGE.FORM_SELECT_SUBMIT} color="primary" handleClick={() => this.handleFormSubmit()} />
                <ButtonWrapper label={APP_LANGUAGE.FORM_SELECT_RESET_FILTERS} handleClick={() => this.handleFormReset()} />
              </fieldset>
            </form>
        )
      }
      else {

        // -- Requête API
        //fetch(API.getAppGlobalFilters()) (APIDataLoader); // KO
        const withAPIFetchAppGlobalFilters = Fetch(API.getAppGlobalFilters());
        const XHRStateCheckersWithAPIFetchAppGlobalFilters = withAPIFetchAppGlobalFilters(XHRStateChecker);  // OK

        return (
          <XHRStateCheckersWithAPIFetchAppGlobalFilters message={APP_LANGUAGE.API_SEARCH_FILTERS_LOADING} />
        )
      }
   }
}
/*
ConnectedSearchForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
*/
const SearchForm = connect(mapStateToProps, mapDispatchToProps)(ConnectedSearchForm);

export default SearchForm;
